#div-articlesidecardcontent-container {
    background: none;
    width: 100%;
    text-align: center;
    border-bottom-width: .05em;
    border-bottom-style: dashed;
    border-bottom-color: rgb(187, 184, 184);
    display: flex;
    transition: background-color 1s ease-out 100ms;
}

.article-link {
    text-decoration: none;
}

#div-articlesidecardcontent-container:hover {
    background-color: #d6d4d0;
}

#div-articlesidecardcontent-image {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#div-articlesidecardcontent-title {
    width: 50%;
    margin-top: 1.5%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

#p-articlesidecardcontent-title {
    text-align: center;
}

#image-articlesidecardcontent {
    width: 100%;
}

#p-articlesidecardcontent-title {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: left;
    font-weight: bold;
    font-size: 90%;
    font-family: Inter, sans-serif;
    color: rgb(64, 65, 66);
}

.date {
    font-size: 70%;
    color: gray;
    letter-spacing: .05em;
    padding: 0;
    margin: 0;
    padding-top: 0.5em;
    display: flex;
    justify-content: left;
    margin-left: 2em;
    font-family: Inter, sans-serif;
}

.div-description {
    display: flex;
    justify-content: left;
}

.description {
    padding: 0;
    margin: 0;
    margin-left: 1.5em;
    margin-top: .5em;
    font-family: Inter, sans-serif;
    font-size: 80%;
    color: rgb(64, 65, 66);
    text-align: left;
    width: 80%;
}

#article-card-link {
    text-decoration: none;
}



@media screen and (max-width: 1200px) {
    .description {
        width: 100%;
    }

    #p-articlesidecardcontent-title {
        font-size: 70%;
    }
}

@media screen and (max-width: 1000px) {
    #div-articlesidecardcontent-title {
        font-size: 60%;
    }
}

@media screen and (max-width: 700px) {
    .description {
        width: 100%;
    }

    .date {
        padding-top: 0.25em;
    }

    #div-articlesidecardcontent-container {
        height: 5%;
    }

    #div-articlesidecardcontent-image {
        width: 30%;
    }

    #div-articlesidecardcontent-title {
        font-size: 130%;
        width: 70%;
    }

    #p-articlesidecardcontent-title {
        justify-content: center;
        
    }
}

@media screen and (max-width: 600px) {
    #p-articlesidecardcontent-title {
        font-size: 100%;
    }

    .date {
        font-size: 60%;
    }

    #div-articlesidecardcontent-title {
        font-size: 90%;
    }
}

@media screen and (max-width: 440px) {
    .description {
        font-size: 65%;
    }

    .date {
        font-size: 55%;
    }
}