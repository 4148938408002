#div-homesidecard-container {
    background-color: rgb(250, 246, 246);
    background-image: url('../../images/background3.png');
    border-radius: .5%;
    border-width: .5em;
    box-shadow: 0em 0em .5em .15em rgba(14, 13, 13, 0.199);
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    padding-top: .2em;
    padding-bottom: .2em;
    font-family: Inter, sans-serif;
}

#div-homesidecard-stitch {
    margin-left: .2em;
    margin-right: .2em;
    border-width: .1em;
    border-style: dashed;
    border-color: rgb(187, 184, 184);
}

.sections {
    padding-left: 5%;
    padding-right: 5%;
}

.section-title {
    text-align: center;
    padding-top: 5%;
    font-family: Qwitcher Grypen, sans-serif;
    font-weight: lighter;
    font-size: 300%;
    color: #3c2600;
    text-shadow: 1px 1px 2px #5c3000;
    text-decoration: underline dashed gray 1px;
}

.section-content {
    font-size: 100%;
}

.section-1-content {
    padding-top: .5em;
}

.separator {
    border-bottom-style: dashed;
    border-bottom-color: rgb(187, 184, 184);
    border-bottom-width: .1em;
}

#p-section-1-content {
    padding-top: 5%;
}

#image-section-1-image {
    /* Border taken from https://codepen.io/mp/pen/kBEeKw */
    width: 100%;
    border-width: 3px 3px 5px 5px;
    border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
    transform: rotate(-1deg);
    border: solid #5c3000;
}

#image-section-2-image {
    width: 100%;
    z-index:0;
    opacity: 90%;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
}




@media screen and (max-width: 1390px) {
    .section-title {
        font-size: 250%;
    }
}