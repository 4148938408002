#div-homemobilecard-container {
    background-color: rgb(250, 246, 246);
    background-image: url('../../images/background3.png');
    border-radius: .5%;
    border-width: .5em;
    box-shadow: 0em 0em .5em .15em rgba(14, 13, 13, 0.199);
    margin-top: .75em;
    margin-left: auto;
    margin-right: auto;
    padding-top: .2em;
    padding-bottom: .2em;
    font-family: Inter, sans-serif;
}

#div-homemobilecard-stitch {
    margin-left: .2em;
    margin-right: .2em;
    border-width: .1em;
    border-style: dashed;
    border-color: rgb(187, 184, 184);
}

#div-homemobilecard-content-container {
    display: flex;
    width: 100%;
}

#div-homemobilecard-image {
    width: 40%;
    margin-left: .5%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

#image-homemobilecard-image {
    width: 100%;
}

#div-homemobilecard-title-content {
    width: 60%;
    margin-right: .5em; 
}

#p-homemobilecard-title {
    text-align: center;
    padding-top: 5%;
    font-family: Qwitcher Grypen, sans-serif;
    font-weight: lighter;
    font-size: 300%;
    color: #3c2600;
    text-shadow: 1px 1px 2px #5c3000;
    text-decoration: underline dashed gray 1px;
}

#p-homemobilecard-content {
    font-size: 100%;
    padding-left: .5em;
}




@media screen and (max-width: 600px) {
    #p-homemobilecard-title {
        font-size: 270%;
    }

    #p-homemobilecard-content {
        font-size: 90%;
    }
}

@media screen and (max-width: 545px) {
    #p-homemobilecard-title {
        font-size: 220%;
    }
}

@media screen and (max-width: 450px) {
    #p-homemobilecard-title {
        margin-left: -50%;
    }

    #p-homemobilecard-content {
        font-size: 80%;
    }

    #div-homemobilecard-image {
        width: 35%;
    }

    #div-homemobilecard-title-content {
        width: 65%; 
    }
}

@media screen and (max-width: 370px) {
    #p-homemobilecard-title {
        margin-left: -50%;
    }
}