body {
    background-color: rgb(233, 226, 226);
    background-image: url("./images/background3.png");
    background-repeat: repeat-x;
    background-repeat: repeat-y;
}

body, html{
    margin:0;
    padding:0;
}

#div-content-and-footer {
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(52, 31, 0, 0.2));
}

/* #appDiv {
    background-color: whitesmoke;
    border-style: solid;
    border-color: rgb(226, 218, 218);
    border-width: 1px;
    border-radius: 25px;
} */